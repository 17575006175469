<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo intervalo</span>
    </button>
    <b-table
      :data="allTimeIntervals"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="initTime"
        label="Horário de início"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.init_time }}
      </b-table-column>

      <b-table-column
        field="endTime"
        label="Horário de término"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.end_time }}</b-table-column
      >

      <b-table-column label="Opções" v-slot="props">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Horário Início">
              <b-input
                placeholder="Informe um horário de início"
                v-model="model.init_time"
                v-cleave="mask.time"
              >
              </b-input>
            </b-field>
            <b-field label="Horário Final">
              <b-input
                placeholder="Informe um horário de término"
                v-model="model.end_time"
                v-cleave="mask.time"
              >
              </b-input>
            </b-field>
            <b-button @click="save" type="button field is-info" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/time-intervals/store/service'
import TimeInterval from '../models/time-interval'

import Cleave from 'cleave.js'

/**
 * We add a new instance of Cleave when the element
 * is bound and destroy it when it's unbound.
 */
const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  },
}

export default {
  name: 'TimeIntervals',
  directives: { cleave },
  data() {
    return {
      model: TimeInterval.model,
      showDetailIcon: false,
      isCardModalActive: false,
      mask: {
        time: { time: true, timePattern: ['h', 'm'] },
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('timeIntervals', ['allTimeIntervals']),
  },
  methods: {
    ...mapActions('timeIntervals', ['getAllTimeIntervals']),
    async init() {
      await this.getAllTimeIntervals()
    },
    async save() {
      const timeInterval = { ...this.model }

      try {
        const save = timeInterval.id
          ? await service.updateTimeInterval(timeInterval)
          : await service.saveTimeInterval(timeInterval)

        await this.$success('Intervalo')
        await location.reload(true)
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    onEdit(item) {
      this.model = { ...item }
      console.log(item)
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o intervalo?')

        if (result.isConfirmed) {
          await service.deleteTimeInterval(id)

          await this.$delete('Intervalo')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
